<template>
  <div class="container" v-if="isFinanceModuleEnabled && hasInvoiceUnpaid">
    <ion-grid>
      <ion-row>
        <ion-col size="1">
          <ion-row>
            <ion-icon color="danger" class="f-icon" :icon="alertCircleOutline"></ion-icon>
          </ion-row>
        </ion-col>
        <ion-col size="11">
          <ion-row>
            <ion-label class="f-bold">{{
              $t('you_have_overdue', { totalInvoices: totalUnpaidInvoices })
            }}</ion-label>
          </ion-row>
          <ion-row>
            <ion-label>
              {{ $t('kindly_make_payment') }}
              <span class="fw-600">
                {{ priceFormatter(currencySymbol, unpaidAmount) }}
              </span>
            </ion-label>
          </ion-row>
          <ion-row>
            <ion-button color="danger" @click="enterPayment">
              <ion-label> {{ $t('make_payment') }} </ion-label>
            </ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>
<script>
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import Clevertap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import { alertCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('sale/payment');
const { mapGetters: mapGettersProduct } = createNamespacedHelpers('sale/product');

export default defineComponent({
  name: 'OverdueNotif',
  inject: ['$storage'],
  setup() {
    const router = useRouter();
    const enterPayment = () => {
      Clevertap.recordEventWithName('Invoices Viewed from Home Screen');
      router.push(`/sale/invoices/select-invoices`);
    };
    return {
      alertCircleOutline,
      enterPayment,
      priceFormatter
    };
  },
  computed: {
    ...mapGetters(['xeroCustomer', 'totalUnpaidInvoices', 'status', 'isFinanceModuleEnabled']),
    ...mapGettersProduct(['list']),
    currencySymbol() {
      return this.user && this.user.country.currency_symbol;
    },
    unpaidAmount() {
      return Number(this.xeroCustomer?.outstanding_amount || 0);
    },
    hasInvoiceUnpaid() {
      return this.unpaidAmount > 0;
    }
  },
  data() {
    return {
      user: null,
      company: null
    };
  },
  watch: {
    async list() {
      this.user = await this.$storage.getUser();
      this.company = await this.$storage.getSelectedCompany();
      this.getTotalUnpaidInvoices();
      this.checkIsFinanceModuleEnabled();
      this.getXeroCustomer();
    }
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    this.company = await this.$storage.getSelectedCompany();
    await this.checkIsFinanceModuleEnabled();
    if (!this.isFinanceModuleEnabled) return;
    this.getXeroCustomer();
    this.getTotalUnpaidInvoices();
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_XERO_CUSTOMER,
      ACTIONS.GET_TOTAL_UNPAID_INVOICES,
      ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED
    ]),
    async checkIsFinanceModuleEnabled() {
      await this[ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getXeroCustomer() {
      await this[ACTIONS.GET_XERO_CUSTOMER]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getTotalUnpaidInvoices() {
      await this[ACTIONS.GET_TOTAL_UNPAID_INVOICES]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    }
  }
});
</script>
<style lang="scss" scoped>
.container {
  border-top: 1px solid #fda29b;
  border-bottom: 1px solid #fda29b;
  padding: 0.4rem;
  z-index: 1;
  background: #fef3f2;
}
.f-bold {
  font-weight: 600;
}
.f-icon {
  font-size: 1.6rem;
}
ion-label {
  margin-bottom: 0.5rem;
}
ion-button {
  margin-left: -1px;
  width: fit-content;
  height: 26px;
  text-transform: capitalize;
  ion-label {
    margin-bottom: 0;
    font-size: 13px;
  }
}
</style>
